window.autoComplete = require('@tarekraafat/autocomplete.js')

window.initAutocomplete = function () {
    new autoComplete({
        data: {                              // Data src [Array, Function, Async] | (REQUIRED)
          src: async () => {
            // API key token
            const token = "this_is_the_API_token_number";
            // User search query
            const query = document.querySelector("#searchAutoComplete").value;
            // Fetch External Data Source
            const source = await fetch(`/api/search?q=${query}`);
            // Format data into JSON
            const data = await source.json();
            // Return Fetched data
            return data;
          },
          key: ["name"],
          cache: false
        },
        
        sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
            if (a.match < b.match) return -1;
            if (a.match > b.match) return 1;
            return 0;
        },
        selector: "#searchAutoComplete",           // Input field selector              | (Optional)
        threshold: 1,                        // Min. Chars length to start Engine | (Optional)
        debounce: 250,                       // Post duration for engine to start | (Optional)
        // searchEngine: "strict",              // Search Engine type/mode           | (Optional)
        resultsList: {                       // Rendered results list object      | (Optional)
            render: true,
            container: source => {
            },
            destination: document.querySelector("#searchAutoComplete"),
            position: "afterend",
            element: "ul"
        },
        maxResults: 6,                         // Max. number of rendered results | (Optional)
        highlight: true,                       // Highlight matching results      | (Optional)
        resultItem: {                          // Rendered result item            | (Optional)
            content: (data, source) => {
                source.innerHTML = data.match + '<span class="category">('+ data.value.type +')</span>';
            },
            element: "li"
        },
        
        onSelection: feedback => {             // Action script onSelection event | (Optional)
            
            window.dispatchEvent(new CustomEvent('search-item-selected', {
                detail: feedback.selection.value
            }));
            
            event.preventDefault();
            return false;
        }
    });
    
}

document.addEventListener("turbolinks:load", function () {
    if (document.querySelector("#searchAutoComplete")) {
        initAutocomplete()
    }
});