import Alpine from 'alpinejs'
import lozad from 'lozad'

require('./bootstrap/splide.js')
require('./bootstrap/lozad.js')
require('./bootstrap/searchfields.js')
require('./bootstrap/searchfield-autocomplete.js')

var Turbolinks = require('turbolinks')
Turbolinks.start()

import Toastify from 'toastify-js'
window.Toastify = Toastify

window.Alpine = Alpine
window.Alpine.start()

const observer = lozad()
observer.observe();
