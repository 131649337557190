window.places = require('places.js');

window.searchQuery = function () {
    return {
        q: '',
        submit() {
            let url = new URL(window.location);
            let params = new URLSearchParams(url.search.slice(1));

            params.delete('page')

            this.q === ''
                ? params.delete('q')
                : params.set('q', this.q);

            params.sort();

            let gotoUrl = cleanUpUrl(`${location.pathname}?${params}`)
            Turbolinks.visit(gotoUrl)
        },
    }
}

window.searchfields = function () {
    return {
        path: '/vacatures',
        radius: 10,
        lat: '',
        lng: '',
        place: '',
        message: '',
        q: '',

        setSearchItem(event) {
            event.preventDefault()
            this.searchItem = event.detail
            this.q = event.detail.name

            // this.submit()
            return false
        },

        setLocation(event) {
            event.preventDefault()
            this.lat = event.detail.lat
            this.lng = event.detail.lng
            this.place = event.detail.name

            this.submit()
            return false
        },

        setRadius() {
            if (this.lat && this.lng) {
                this.submit()
            }
        },

        submit() {
            let url = new URL(window.location);
            let params = new URLSearchParams(url.search.slice(1));

            params.delete('page')
            params.delete('in')
            params.delete('radius')
            params.delete('lat')
            params.delete('lng')

            if (this.place !== '') {
                params.set('in', this.place);
                params.set('radius', this.radius);
                params.set('lat', this.lat);
                params.set('lng', this.lng);

            }

            if(this.searchItem){
                // Hier eventueel checken of ie al is gezet en zo ja, toevoegen
                let newParam = params.get(this.searchItem.type)
                    ? params.get(this.searchItem.type) + ',' + this.searchItem.slug
                    : this.searchItem.slug;

                params.set(this.searchItem.type, newParam)

            }else{
                this.q === ''
                    ? params.delete('q')
                    : params.set('q', this.q);
            }

            params.sort();

            let gotoUrl = cleanUpUrl(`${this.path}?${params}`)
            Turbolinks.visit(gotoUrl)
        },
    }
}

window.initLocationSelectAlgolia = function () {

    if (document.querySelector('#algolia-geocoder') !== null){

        var algoliaPlacesAutocomplete = places({
            appId: 'plSX7F4KXOCD',
            apiKey: '2869b88eb840830aa2869e8901e8f485',
            container: document.querySelector('#algolia-geocoder'),
            language: 'nl',
            countries: 'nl',
        });

        algoliaPlacesAutocomplete.on('change', (result) => {

            let locationData = {
                name: result.suggestion.name,
                lat: result.suggestion.latlng['lat'],
                lng: result.suggestion.latlng['lng'],
            }

            window.dispatchEvent(new CustomEvent('location-changed', {
                detail: locationData
            }));
        })
    }
}

window.stickySearch = function() {
    return {
        showStickySearch : window.innerWidth < 768,
        resetStickSearch(){
            this.showStickySearch = false
        },

        setShowStickySearchFromEvent(event) {
            this.showStickySearch = event.detail;
        }
    }
}

document.addEventListener("turbolinks:load", function () {
    initLocationSelectAlgolia()

    // Only for the joboffers page register a scroll event listener for stickysearch
    if(window.location.pathname === '/vacatures'){
        document.addEventListener('scroll', checkStickySearchNav);
    }else{
        document.removeEventListener('scroll', checkStickySearchNav);
    }
});


window.prevScrollY = 0;

window.checkStickySearchNav = function(){

    if(window.innerWidth < 768){
        return false;
    }

    if(prevScrollY < 400 && window.scrollY >= 400){
        window.dispatchEvent(new CustomEvent('toggle-sticky-search', { detail: true}));
    }

    if(prevScrollY >= 400 && window.scrollY < 400){
        window.dispatchEvent(new CustomEvent('toggle-sticky-search', { detail: false }));
    }

    prevScrollY = window.scrollY
}
