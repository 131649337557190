import '@splidejs/splide/dist/css/splide.min.css'
import Splide from '@splidejs/splide';

let options = {
    rewind : false,
    rewind : true,
    arrows: true,
    pagination: false,
    fixedWidth: 240,
    gap    : '1rem',
    
    classes: {
      // Add classes for arrows.
      arrows: 'hidden opacity-25 hover:opacity:100 lg:inline-block splide__arrows your-class-arrows',
      arrow : 'splide__arrow your-class-arrow',
      prev  : 'sm:-ml-16 splide__arrow--prev your-class-prev',
      next  : 'sm:-mr-16 splide__arrow--next your-class-next',
      
      // Add classes for pagination.
      pagination: 'splide__pagination your-class-pagination', // container
      page      : 'splide__pagination__page your-class-page', // each button
  },
}

window.initSplide = function () {
    if (document.getElementsByClassName('splide').length) {
        window.splide = new Splide('.splide', options).mount()
    }
}

document.addEventListener("turbolinks:load", function () {
    initSplide()
});