require('./bootstrap.js')

window.cleanUpUrl = function (url) {
    let lastchar = url.substring(url.length - 1)

    if (lastchar === '&' || lastchar === '?') {
        url = url.substring(0, url.length - 1)
    }
    url = url.replace('&&', '&')

    return decodeURIComponent(url)
}
